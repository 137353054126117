import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as A from "fp-ts/Array";
import * as I from "fp-ts/IO";
import { IO } from "fp-ts/IO";
import * as IE from "fp-ts/IOEither";

interface Options {
  paymentId?: string;
  preAuthTokenId?: string;
  completed?: () => void;
}

interface IFrameMessage {
  action: "close" | "completed";
  data?: {
    [key: string]: any;
  };
}

interface PaymentDetails {
  type: "PAYMENT" | "PRE_AUTH_TOKEN";
  id: string;
}

const oGetCurrentScriptAttribute: (attribute: string) => O.Option<string> = (
  attribute
) =>
  pipe(
    O.fromNullable(document.currentScript as HTMLScriptElement),
    O.mapNullable((a) => a.getAttribute(attribute))
  );

const ioThrowError: (error: Error) => I.IO<void> = (error) => () => {
  throw error;
};

const oOnlineEndpoint: O.Option<string> = pipe(
  O.fromNullable(document.currentScript as HTMLScriptElement),
  O.map((a) => new URL(a.src)),
  O.map(({ protocol, host }) => `${protocol}//${host}`)
);

const ioCreateButtonElement =
  ({
    onlineEndpoint,
    locale,
    type,
    color,
    alt,
  }: {
    onlineEndpoint: string;
    locale: string;
    type: string;
    color: string;
    alt?: string;
  }): I.IO<HTMLImageElement> =>
  () => {
    const img = document.createElement("img");
    img.src = `${onlineEndpoint}/images/${locale}-${type}-${color}.svg`;
    img.alt = alt || "Pay with Satispay";
    img.style.height = "50px";
    img.style.cursor = "pointer";
    return img;
  };

const ioCreateIFrameElement =
  ({ src }: { src: string }): I.IO<HTMLIFrameElement> =>
  () => {
    const iframe = document.createElement("iframe");
    iframe.style.margin = "0";
    iframe.style.padding = "0";
    iframe.style.position = "fixed";
    iframe.style.top = "0";
    iframe.style.left = "0";
    iframe.style.width = "100%";
    iframe.style.height = "100%";
    iframe.style.border = "0";
    iframe.style.zIndex = "9999";
    // already provided by the Modal overlay
    // iframe.style.backgroundColor = 'rgba(0, 0, 0, .7)'
    iframe.src = src;
    return iframe;
  };

const ioeInsertBeforeParentNode: (child: Node) => IE.IOEither<Error, Node> = (
  child
) =>
  pipe(
    O.fromNullable(document.currentScript as HTMLScriptElement),
    IE.fromOption(() => new Error("Missing current script")),
    IE.chain((currentScript) =>
      pipe(
        O.fromNullable(currentScript.parentNode),
        IE.fromOption(() => new Error("Missing parent node")),
        IE.map((node) => node.insertBefore(child, currentScript))
      )
    )
  );

const oPaymentID: O.Option<PaymentDetails> = pipe(
  oGetCurrentScriptAttribute("data-payment-id"),
  O.map((id) => ({
    type: "PAYMENT",
    id,
  }))
);

const oPreAuthTokenID: O.Option<PaymentDetails> = pipe(
  oGetCurrentScriptAttribute("data-pre-auth-token-id"),
  O.map((id) => ({
    type: "PRE_AUTH_TOKEN",
    id,
  }))
);

const oPaymentDetails: O.Option<PaymentDetails> = pipe(
  oPaymentID,
  O.alt(() => oPreAuthTokenID)
);

const color: string = pipe(
  oGetCurrentScriptAttribute("data-color"),
  O.getOrElse(() => "red")
);

const type: string = pipe(
  oGetCurrentScriptAttribute("data-type"),
  O.getOrElse(() => "pay")
);

const oNavigatorLanguage: O.Option<string> = pipe(
  navigator.language.split("-"),
  A.head
);

const locale: string = pipe(
  oGetCurrentScriptAttribute("data-locale"),
  O.alt(() => oNavigatorLanguage),
  O.getOrElse(() => "en")
);

const closable: boolean = pipe(
  oGetCurrentScriptAttribute("data-closable"),
  O.map((stringClosable) => stringClosable === "true"),
  O.getOrElse(() => true)
);

const ioInitializeStandardWebButton: I.IO<void> = pipe(
  oPaymentDetails,
  IE.fromOption(() => new Error("Missing payment details")),
  IE.chain((paymentDetails) =>
    pipe(
      oOnlineEndpoint,
      IE.fromOption(() => new Error("Missing online endpoint")),
      IE.chain((onlineEndpoint) =>
        pipe(
          ioCreateButtonElement({
            onlineEndpoint,
            locale,
            type,
            color,
          }),
          I.chain(ioeInsertBeforeParentNode)
        )
      ),
      IE.map((buttonElement) => {
        const wbOpts =
          paymentDetails.type === "PAYMENT"
            ? {
                paymentId: paymentDetails.id,
              }
            : {
                preAuthTokenId: paymentDetails.id,
              };

        const wb = new SatispayWebButton(wbOpts);

        buttonElement.addEventListener("click", (e) => {
          e.preventDefault();
          wb.open();
        });
      })
    )
  ),
  IE.fold(ioThrowError, I.of)
);

const preventBodyScroll: IO<void> = () => {
  document.body.style.overflowY = "hidden";
};

const restoreBodyScroll: IO<void> = () => {
  document.body.style.overflowY = "";
};

class SatispayWebButton {
  paymentId: string | undefined;
  preAuthTokenId: string | undefined;
  iframeElement: HTMLIFrameElement | undefined;
  completed?: () => void;

  constructor({ paymentId, preAuthTokenId, completed }: Options) {
    this.paymentId = paymentId;
    this.preAuthTokenId = preAuthTokenId;

    if (!this.paymentId && !this.preAuthTokenId) {
      throw new Error(`Missing 'paymentId' or 'preAuthTokenId'`);
    }

    this.completed = completed;

    window.addEventListener("message", (event: MessageEvent) => {
      if (!this.iframeElement) return;

      if (event.source === this.iframeElement.contentWindow) {
        this.handleIFrameMessage(event.data);
      }
    });
  }

  handleIFrameMessage = (message: IFrameMessage) => {
    switch (message.action) {
      case "close":
        this.close();
        break;
      case "completed":
        this.completed && this.completed();
        break;
    }
  };

  close() {
    const effect = pipe(
      () => {
        if (this.iframeElement) {
          this.iframeElement.remove();
        }
      },
      I.chain(() => restoreBodyScroll)
    );
    effect();
  }

  open() {
    if (this.iframeElement) {
      this.iframeElement.remove();
    }

    let iframeUrl: string;
    if (this.paymentId) {
      iframeUrl = `/pay/${this.paymentId}?mode=iframe`;
    } else if (this.preAuthTokenId) {
      iframeUrl = `/authorize/${this.preAuthTokenId}?iframe=true`;
    } else {
      throw new Error(`Missing 'paymentId' or 'preAuthTokenId'`);
    }

    if (!closable) iframeUrl += "&closable=false";

    const ioCreateIFrame = pipe(
      oOnlineEndpoint,
      IE.fromOption(() => new Error("Missing online endpoint")),
      IE.chain((onlineEndpoint) =>
        IE.rightIO(
          ioCreateIFrameElement({
            src: `${onlineEndpoint}${iframeUrl}`,
          })
        )
      ),
      IE.map((iframeElement) => {
        this.iframeElement = iframeElement;
        document.body.appendChild(iframeElement);
      }),
      IE.chainFirst(() => IE.fromIO(preventBodyScroll)),
      IE.fold(ioThrowError, I.of)
    );

    ioCreateIFrame();
  }
}

window.SatispayWebButton = {
  configure: (options: Options) => {
    return new SatispayWebButton(options);
  },
};

ioInitializeStandardWebButton();
